<template>
  <section class="verify">
    <section v-if="!loading" class="verify-container">
      <template v-if="!error">
        <h1 class="verify-title">
          Your registration completed
        </h1>

        <AppButton
          color="var(--button-primary-color)"
          dark
          @click="redirectUser"
        >
          Continue
        </AppButton>
      </template>
      <template v-else>
        <span class="error-message">{{ error }}</span>
      </template>
    </section>
  </section>
</template>

<script>
import Fire from '@/plugins/firebase'
export default {
  name: 'VerifyEmail',
  data() {
    return {
      currentUser: null,
      error: null,
      loading: true
    }
  },
  async mounted() {
    try {
      this.currentUser = await Fire.getCurrentUser()
      await Fire.auth().applyActionCode(this.$route.query.oobCode)
    } catch (err) {
      this.error = err
    } finally {
      this.loading = false
    }
  },
  methods: {
    redirectUser() {
      // if use router push then firebase user won't update
      if (this.currentUser) {
        window.location.href = `${window.location.origin}/dashboard`
      } else {
        window.location.href = `${window.location.origin}/login`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.verify {
  padding: 136px 0;

  @include breakpoint-reverse(small) {
    padding: 0;
  }
}

.verify-container {
  margin: 0 auto;
  border-radius: var(--border-radius-default);
  padding: 28px 60px;
  max-width: 460px;
  text-align: center;
  background-color: var(--background-secondary-color);

  @include breakpoint-reverse(small) {
    padding: 28px;
  }
}

.verify-title {
  margin-bottom: 12px;
}

.error-message {
  color: var(--error-color);
}
</style>
