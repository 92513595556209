<template>
  <section class="reset-password">
    <div v-if="isPasswordChanged" class="reset-password-container">
      <h1 class="reset-password-title">
        Password changed
      </h1>

      <router-link :to="{ name: 'Dashboard' }" class="reset-password-subtitle">
        Click here to continue UnGhosted
      </router-link>
    </div>
    <div v-else class="reset-password-container">
      <h1 class="reset-password-title">
        Create new password
      </h1>

      <h2 class="reset-password-subtitle">
        Your new password must be different from previous used password
      </h2>
      <AppInput
        alt
        class="input"
        label="Password"
        :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        placeholder="Password"
        :value.sync="pass"
        :type="isShowPassword ? 'text' : 'password'"
        @click:append="isShowPassword = !isShowPassword"
      />

      <AppInput
        alt
        class="input"
        label="Confirm password"
        :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
        placeholder="Confirm password"
        :value.sync="confirmPass"
        :type="isShowPassword ? 'text' : 'password'"
        @click:append="isShowPassword = !isShowPassword"
        @pressEnter="resetPassword"
      />
      <span v-show="error" class="error-message">{{ error }}</span>

      <div>
        <AppButton
          color="var(--button-primary-color)"
          class="reset-password-button"
          dark
          :loading="loading"
          @click="resetPassword"
        >
          Reset password
        </AppButton>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      pass: '',
      confirmPass: '',
      error: '',
      loading: false,
      isPasswordChanged: false,
      isShowPassword: false
    }
  },
  mounted() {
    if (!this.$route.query.oobCode) {
      this.$router.push({ name: 'LogIn' })
    }
  },
  methods: {
    async resetPassword() {
      if (!this.pass || !this.confirmPass) {
        this.error = 'All fields are required'
        return
      }

      if (this.pass !== this.confirmPass) {
        this.error = 'Passwords must be equal'
        return
      }

      this.loading = true

      try {
        const email = await this.$firebase
          .auth()
          .verifyPasswordResetCode(this.$route.query.oobCode)
        await this.$firebase
          .auth()
          .confirmPasswordReset(this.$route.query.oobCode, this.pass)
        await this.$firebase
          .auth()
          .signInWithEmailAndPassword(email, this.pass)
        this.isPasswordChanged = true
      } catch (err) {
        this.error = err
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.reset-password {
  padding: 136px 0;

  @include breakpoint-reverse(small) {
    padding: 0;
  }
}

.reset-password-container {
  margin: 0 auto;
  border-radius: var(--border-radius-default);
  padding: 28px 60px;
  max-width: 460px;
  background-color: var(--background-secondary-color);

  @include breakpoint-reverse(small) {
    padding: 28px;
  }
}

.reset-password-title {
  font-size: var(--font-size-h1);
  text-align: center;
}

.reset-password-subtitle {
  display: block;
  margin-top: 5px;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-base);
  text-align: center;
}

.reset-password-button {
  display: block;
  margin: 15px auto;
  width: 240px;

  @include breakpoint-reverse(small) {
    width: 100%;
  }
}

.input {
  margin-bottom: 16px;
}

.error-message {
  color: var(--error-color);
}
</style>
