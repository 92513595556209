<template>
  <section>
    <ResetPassword v-if="mode === 'resetPassword'" />
    <VerifyEmail v-if="mode === 'verifyEmail'" />
  </section>
</template>

<script>
import ResetPassword from '@/components/ResetPassword'
import VerifyEmail from '@/components/VerifyEmail'
export default {
  name: 'AuthAction',
  components: { ResetPassword, VerifyEmail },
  computed: {
    mode() {
      return this.$route.query.mode
    }
  }
}
</script>
